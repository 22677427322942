import { configureStore, combineReducers } from "@reduxjs/toolkit";

import authSlice from "../slices/authSlice";
import loginSlice from "../slices/loginSlice";
import notificationSlice from "../slices/notificationSlice";
import tenantSlice from "../slices/tenantSlice";
import dashboardSlice from "../slices/dashboardSlice";
import marketingSlice from "../slices/marketingSlice";
import seoSlice from "../slices/seoSlice";
import eventSlice from "../slices/eventSlice";
import twilioSlice from "../slices/twilioSlice";

const combinedReducer = combineReducers({
  auth: authSlice,
  login: loginSlice,
  notification: notificationSlice,
  tenant: tenantSlice,
  dashboard:dashboardSlice,
  marketing: marketingSlice,
  seo: seoSlice,
  event: eventSlice,
  twilio: twilioSlice,
});

const rootReducer = (state, action) => {
  if (action.type === "login/setLoginFlag") {
    // check for action type
    state = undefined;
  }
  return combinedReducer(state, action);
};

const store = configureStore({
  reducer: rootReducer,
});

export default store;
