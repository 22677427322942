import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  getAllTenantAPI,
  getCurrentTenantAPI,
  updateTenantAPI,
  updateTenantStatusAPI,
} from "../api/TenantApi";

const initialState = {
  userData: null,
  allUsers: null,
  loading: false,
};

export const updateTenant = createAsyncThunk(
  "update/tenant",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await updateTenantAPI(payload);
      return response.data;
    } catch (err) {
      return rejectWithValue({
        errorMessage: err?.message,
      });
    }
  }
);

export const getCurrentTenant = createAsyncThunk(
  "get/current/tenant",
  async (tenantId, { rejectWithValue }) => {
    try {
      const response = await getCurrentTenantAPI(tenantId);
      return response.data;
    } catch (err) {
      return rejectWithValue({
        errorMessage: err?.message,
      });
    }
  }
);

export const getAllTenant = createAsyncThunk(
  "tenant/getAllTenant",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await getAllTenantAPI(payload);
      return response.data;
    } catch (err) {
      return rejectWithValue({
        errorMessage: err?.message,
      });
    }
  }
);

export const updateTenantStatus = createAsyncThunk(
  "update/tenant-status",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await updateTenantStatusAPI(payload);
      return response.data;
    } catch (err) {
      return rejectWithValue({
        errorMessage: err?.message,
      });
    }
  }
);

const tenantSlice = createSlice({
  name: "tenant",
  initialState,
  extraReducers: (builder) => {
    //get user
    builder.addCase(getCurrentTenant.pending, (state) => {
      state.loading = false;
    });
    builder.addCase(getCurrentTenant.fulfilled, (state, action) => {
      console.log(action.payload.response);
      state.userData = action.payload.response;
      state.loading = true;
    });
    builder.addCase(getCurrentTenant.rejected, (state) => {
      state.loading = false;
    });

    //update user
    builder.addCase(updateTenant.pending, (state) => {
      state.loading = false;
    });
    builder.addCase(updateTenant.fulfilled, (state, action) => {
      state.userData = action.payload.response;
      state.loading = true;
    });
    builder.addCase(updateTenant.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(getAllTenant.pending, (state) => {
      state.loading = false;
    });
    builder.addCase(getAllTenant.fulfilled, (state, action) => {
      state.allUsers = action.payload?.response;
      state.loading = true;
    });
    builder.addCase(getAllTenant.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(updateTenantStatus.pending, (state) => {
      state.loading = false;
    });
    builder.addCase(updateTenantStatus.fulfilled, (state, action) => {
      // const { arg } = action.meta;
      // if (arg) {
      //   console.log(arg, state.allUsers, state.userData);
      //   state.allUsers = state.allUsers?.map((item) => {
      //     if (item.tenantId === arg.tenantId) {
      //       item.isActive = arg.statusId;
      //     }
      //     return item;
      //   });
      // }

      state.loading = true;
    });
    builder.addCase(updateTenantStatus.rejected, (state) => {
      state.loading = false;
    });
  },
});

export default tenantSlice.reducer;
