import API from "./API";

export const getCurrentTenantAPI = (tenantId) => {
  return API.get(`/tenants/${tenantId}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error.response;
    });
};

export const updateTenantAPI = (payload) => {
  return API.put("/tenants", payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error.response;
    });
};

export const updateTenantStatusAPI = (payload) => {
  return API.put(`/tenants/status/${payload.statusId}/${payload.tenantId}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error.response;
    });
};

export const getAllTenantAPI = (payload) => {
  return API.get(`/tenants?page=${payload?.page}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};
