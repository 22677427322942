import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { forgetPasswordAPI, loginAPI, signupAPI } from "../api/AuthApi";
import jwt_decode from "jwt-decode";

const initialState = {
  login: null,
  loading: null,
  error: {},
};

export const singup = createAsyncThunk(
  "auth/singup",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await signupAPI(payload);
      window.location = "/";
      return response;
    } catch (err) {
      return rejectWithValue({
        errorMessage: err?.message,
      });
    }
  }
);

export const login = createAsyncThunk(
  "auth/login",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await loginAPI(payload);
      if (response.data.success) {
        if (
          jwt_decode(response?.data?.response?.accessToken)?.role === "ADMIN"
        ) {
          window.location = "/admin/dashboard";
        } else if (
          jwt_decode(response?.data?.response?.accessToken)?.role === "STAFF"
        ) {
          window.location = "/admin/seopages";
        } else {
          window.location = "/dashboard";
        }

        return response;
      } else {
        throw new Error(response.data.message);
      }
    } catch (err) {
      return rejectWithValue({
        errorMessage: err?.message,
      });
    }
  }
);

export const forgetPassword = createAsyncThunk(
  "auth/forgetPassword",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await forgetPasswordAPI(payload);
      return response;
    } catch (err) {
      return rejectWithValue({
        errorMessage: err?.message,
      });
    }
  }
);

const authSlice = createSlice({
  name: "auth",
  initialState,
  extraReducers: (builder) => {
    //login user
    builder.addCase(login.pending, (state) => {
      state.loading = false;
    });
    builder.addCase(login.fulfilled, (state, action) => {
      state.login = action.payload.data.response.accessToken;
      sessionStorage.setItem("token", action.payload.data.response.accessToken);
      state.loading = true;
    });
    builder.addCase(login.rejected, (state, action) => {
      state.loading = null;
      state.error = action.payload;
    });

    //signup user
    builder.addCase(singup.pending, (state) => {
      state.loading = false;
    });
    builder.addCase(singup.fulfilled, (state) => {
      state.loading = true;
    });
    builder.addCase(singup.rejected, (state) => {
      state.loading = null;
    });
  },
});

export default authSlice.reducer;
